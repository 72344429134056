<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col lg="8" cols="12">
        <new-perseu-card title="Histórico de terapias">
          <div class="px-3">
            <v-list v-if="hasTherapies" two-line>
              <v-list-item
                v-for="(therapy, index) in therapies"
                :key="index"
                class="border-bottom-grey py-3"
                @click="openDiagnosis(therapy.id)"
              >
                <v-list-item-content>
                  <v-list-item-title class="mb-2">
                    <v-icon class="mr-1" color="primary">account_circle</v-icon>
                    <span class="hidden-xs-only">Terapeuta:</span>
                    {{ therapy.therapist.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="d-flex align-center">
                    <v-icon small class="mr-1">history</v-icon>
                    {{ therapy.createdAtLocal | distanceOfToday }} atrás
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">open_in_new</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <template v-else>
              <empty-list text="Nenhuma terapia realizada ainda." />
            </template>
          </div>
        </new-perseu-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTherapy" width="800">
      <therapy
        v-if="dialogTherapy"
        :therapy="selectedTherapy"
        @close="closeDialog"
      ></therapy>
    </v-dialog>
  </div>
</template>

<script>
import {
  findByLoggedUserPatient,
  findOnePatient,
} from "@/services/external/therapies-service";
export default {
  components: {
    therapy: () => import("@/domains/therapy-patient/Therapy"),
  },
  data: () => ({
    therapies: [],
    dialogTherapy: false,
    selectedTherapy: {},
  }),
  computed: {
    hasTherapies() {
      return !!this.therapies && this.therapies.length > 0;
    },
  },
  created() {
    this.find();
  },
  methods: {
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findByLoggedUserPatient();
        this.therapies = data;
      } catch (error) {
        this.$toasted.global.defaultError();
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async openDiagnosis(id) {
      this.$router.push({ name: "userReview", query: { id } });
    },
    closeDialog() {
      this.dialogTherapy = false;
    },
  },
};
</script>

<style></style>
